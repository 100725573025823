/**** Responsive ****/
@media (min-width: 1200px){        
    .mobile_hmenu_list{ display: none;}/* Mobile cms menu */
    .hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
    .cur-ord-rec-mobile{ display: none;}
}   
@media (min-width: 576px){    
    .modal-dialog { margin: 1.75rem auto; }
    .modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}
@media(max-width: 1330px) and (min-width: 991px){
    .promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; font-size: 16px; padding: 12px 10px;}
}
@media (min-width: 1500px){   
    .container-full{ padding-left: 0; padding-right: 0  } 
}
@media (max-width: 1500px) {
    .product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li img {width: auto;}
}
/* Max width */
@media (max-width: 1350px){
    .container, .container-full{padding-left: 15px; padding-right: 15px;}
}

@media (max-width: 1280px){
    ul.hmenu_list > li > a, .hsign_sec a{font-size: 13px;}
    ul.hmenu_list > li > a{padding: 15px 5px 12px;margin: 0 2px}    
    .hordertype_sec a { padding: 15px 20px 13px;  margin: 0 15px 0 20px;font-size: 14px;}    
    .htico_cart{margin-left: 10px}    
    .footer-bottom .container { padding: 0 15px;  flex-flow: column wrap;}    
    .footer-bottom-right { position: relative; top: inherit; left: inherit; margin-top: 5px;}    
    .product-details .container-full > div .inn-product-popup .slick-slider .slick-thumb li img {max-width: 100px;}
}
@media (max-width: 1240px){
    .header_in::before, .header_in::after, .ourstory-inner-cont:before{display: none}	
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before{display: none !important}    
.hmenu_actions{float: right}
.product-view-more {  margin-top: 15px;}    
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.mega_menuin [class*='col-']{width: 100%;}
.logo{width: 100%;max-width:200px;}
.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
.hcategory_sec{ background: #EBEBEB;  position: relative; border-bottom: 2px solid #181818; }
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; font-size: 16px; text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hcategory_trigger:after{ 
    position: absolute; background: url(../images/menu_ico.png) no-repeat; background-size: contain; width: 22px;
    height: 20px; content: "";  right: 0; top: 2px;
} 
.hcategory_trigger.active:after{ 
    background: url(../images/close-block.png) no-repeat; background-size: contain; width: 16px;
    height: 16px; top:3px; right:5px;
 }
 .hcategory_menu{background: #f4f4f4; position: absolute; left: 0; top: 0; right: 0; max-height: 400px; opacity: 0;
visibility: hidden; -webkit-transition: all 400ms; transition: all 400ms; overflow: auto;}
.hcategory_menu .mCSB_scrollTools{ opacity: 1;}
.hcategory_menu .mCSB_scrollTools .mCSB_draggerRail{ background-color: #6e5546;}
.hcategory_menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{ background-color: #191919;}
.hcategory_menu.open{ opacity: 1; visibility: visible;z-index: 9;}
.hcategory_menulist{ list-style: none; padding: 0; margin: 0;}
.hcategory_menulist > li > a{background: #f8f8f8; color: #000; font-size: 18px; padding: 12px 45px 12px 15px; display: block; text-transform: uppercase; font-family: 'poppins'; font-weight: 700;}
.hcategory_menulist > li > a:hover{ color: #105152;}
.hcategory_submenu{ list-style: none; padding: 0; margin: 0; }
.hcategory_submenu >li >a{ color: #000; font-size: 18px; padding: 12px 15px 12px 35px; display: block; font-family: 'poppins'; font-weight: 700;}
.hcategory_submenu >li >a:hover{ color: #105152;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.banner{ margin-top: 25px;}
.banner .container{ padding: 0 15px;}
.banner-top-text{position: static;left: 0;right: 0;margin: 0 auto 50px auto;width: 100%; padding: 0 50px;}    
.banner-text-box{margin-right: 0;}
.featured-products-main h2, .serving-passionately-right h3{font-size: 40px; }
/*.banner-info p{font-size: 14px;    line-height: 20px;}*/
.banner-info a{
    display: none;
}
/*.banner-info{
    top:5%
}*/

.ta-box-inner {
    text-align: center;
}
.tkaw-icons span{
    font-size: 12px;
}
/*.white-popup.login-popup,*/ 
/*.white-popup.signup-popup, */
.white-popup.signup-corparateone-popup, 
.white-popup.signup-corparatetwo-popup, 
.white-popup.guest-popup{
    background: inherit;
    max-width: 352px;
}
.qrcode img {
     padding: 40px;
    width: 100%;
    border: 1px solid #fff;
}
.inside-dine-popup-rhs {
    float: none;
    background: #000;
    padding: 15px 60px 0px 60px;
    position: relative;
    z-index: 9;
    border: 1px solid #04cadf;
    width: 100%;
    transition: 0.4s linear all;
}
.dine-input input{
    width:100%;
    display:flex;
    margin: 0 auto;
}
.pop-whole-lhs-inner p {
    font-size: 12px;
}
.ft-tp-col1 img, .ft-tp-col2 img, .ft-tp-col3 img {
    margin: 0 auto 0px;
    display: block;
}

.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 15px;}
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 28px;}
.innerproduct-col{width: 32.29061553985873%;margin-right: 1.5136226034308779%;margin-bottom: 15px;}
.chk_hea{font-size: 16px;}
.checkout-total .cdd-details-lhs{width: 100%;margin-bottom: 10px;} 
.cdd-details-lhs .checkout-heading:after{display: none}    
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{width: 100%;}
.checkout-total .checkout-control-group-middle{padding: 10px 0 0px 0;}
.checkout-control-group-top [class*='col-md']{margin-bottom: 10px;}
.checkout-control-group-middle [class*='col-md']{margin-bottom: 10px;}
.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.main_tabsec_inner, .rewards-tab-section .main_tabsec_inner, .promation-tab-section .main_tabsec_inner{padding: 20px 15px;}
.title_sec h2{font-size: 34px;}
.filter_tabsec .nav-tabs>li>a{font-size: 17px;}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.banner-left{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.banner-text-box{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;}
.banner-right{margin-top: 0;}
.banner-right-content{text-align: left;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{font-size: 32px;}
.delivery-item span{font-size: 17px;}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.footer-inner [class*='col-']{width: 33.33%;}
.footer-inner .news-letter{width: 100%;margin-top: 10px;}
.cart_footer_totrow p{font-size: 20px;}
.cart_footer_totrow span{font-size: 20px;}
.delivery_info h4{font-size: 14px;}
.delivery_info p{font-size: 14px;}
.cart_action .btn_grey{font-size: 14px;}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{font-size: 23px;}
.tnk-order h3{font-size: 28px;}
.tnk-order p{ font-size: 14px;}
.tick img{width: 60px;}
.tick h2{font-size: 34px;margin-top: 10px;}
.tick span{font-size: 16px;}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2, .promo-earned-content span{ font-size: 20px; }
.promo-earned-content h2 span sup {font-size: 20px; }
.abc_code span{ font-size: 17px; }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {font-size: 18px; }
.redeem_popsec .promo-earned-content h4{ font-size: 24px; }
.redeem_popsec .promo-earned-content p{ margin: 0; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{ font-size: 30px;}
/* Account information page */

.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }       
.productlist-main-div{ width: 100%}    
}
@media (max-width: 1180px){
    .pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
    .pkselected_package .form-group { min-width: auto;}
    .pkselected_filtersec .time-label {width: 171px;}
    .pkselected_break .form-group {min-width: auto;}
    .row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
    
    .testimonial-section{background-image: none; background: #000; min-height: inherit; padding: 40px;}    
    .testimonial-section > div{padding: 0}
    .promo-three-clm2 span{font-size: 13px;}
    .promo-three-clm2 h4{font-size: 18px}
    /*.ft-bt-col4{padding-left: 0}*/
}
@media (max-width: 1100px){
    .order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
    .order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
    .order_delivery_mobile_tooltip p{ font-size: 13px; }	
    .chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
    .ord-amount [class*='col-']{width: 100%;}
    .earned_points{float: none;}
    .ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
    .sprite_lst_icon{display: none}
    .web_order_typecls li a h5{font-size: 16px}    
    .f-nav > ul > li { padding-right: 20px;}    
    .productlist-main-div{ padding: 5px 0px 20px;}    
}

@media (max-width: 1000px){
    .mobile_accountdel_body, .mobile-order-nowbtn, .mobile-login{display: block;}
}

@media (max-width: 1080px){
    .header_bot{display: block; margin: 15px 0 0 0;}       
    /*.logo-main-section{-webkit-box-align: center;-ms-flex-align: center;align-items: center;justify-content: space-around;} */   
    .htico_cart { margin-left: 7px;}    

    .mobile-login ul{right: 0; left: inherit;}   
    .mobile-login {right: 5px;}    
    a.controller-nav img { width: 23px; }    
    /*.menu-section-left{display: none}    */
    .menu-section-mobile-inner, .mobile-menu-section-left{display: block}    
    .product_det_cards > ul{justify-content: space-between}    
    .product_det_cards > ul li.det_card{width: 49%; margin: 0 0 20px 0;}    
    .choose-option-hover{width: 260px}    
    .choose-option-hover ul{padding: 5px}
    .choose-option-hover ul li a{font-size: 13px;font-family: 'avantgarde_bk_btbook';}
    .choose-option-hover ul li { margin: 4px 0;}
    .pdr_right.price-fixed{top: 136px}    

    .welcome-rhs { padding: 40px 0 40px 40px;}    
    .footer-bottom-middle ul li { margin: 0 3px;}    
    .footer-bottom-middle ul li a{font-size:14px;}    
    .footer-bottom-right h3{font-size: 14px}   
    .wh-lhs-top h2 { font-size: 50px;}    
    .wh-lhs-top p {font-size: 18px;}    
    .featured-products-section .products-list-ulmain li { width: 32%; margin: 0 2% 20px 0;}    
    .featured-products-section .products-list-ulmain li:nth-child(3n) { margin-right: 0;}
    .featured-products-section .products-list-ulmain li:nth-child(4n) { margin-right: 2%;}    
    /*.footer-top img {width: 160px;} */   
    .promo-three-clm2 h3{font-size: 26px;}   
    section.menu-nav-section {position: relative;top: inherit;padding: 0;} 

    .product-details .container-full > div .inn-product-popup .prodet_bansec {flex: 1 1 100%;max-width: 100%;margin-bottom: 25px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row {flex: 1 1 100%;max-width: 100%;}
}

@media (max-width: 1024px){
    .home-banner-main{ margin-top: 0px;;}
    .delivery{margin-top: 30px;}    
    .prd_chosen_row{padding: 25px 20px 20px 20px;}
    .congrats.redeem-promation-body{padding: 24px 0;}    
    .prd-sub{max-width: 100%;}
    .prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
    .prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
    .product_chosen_hea h6{font-size: 14px;}
    .tnk-you{padding:45px 0 30px 0;}
    .number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
    .number-break .back_bx:after {top: 10px; font-size: 26px;}
    .number-break {padding: 25px 30px 25px 45px;}  
    .award_galimg{ height: 250px }  
    .head_left h4{font-size: 19px} 
    .head_right h4{font-size: 16px}    
    .order_amt h3{font-size: 21px}     
    .top-bar p{font-size: 12px}    
    .top-bar span { font-size: 14px; padding: 14px 30px;}
    
}
@media (max-width: 991px){
    .myaccount-banner{
            padding: 60px 0px;
    }
    /*.logo-main-section{height: 175px;}*/
    .innerproducts-list-maindiv{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
    }
    .common-inner-banner .syed-myacc-head {
    top: 10%;
}
    
    /*.about-section{ height: auto;  margin-bottom: 20px; }*/
    .abt-inner h4{ font-size: 45px; }
    .menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{ border-bottom:none; }
    .menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before{
        height: 100%;
        bottom: 0px;
    }
	.contact_form h3 { z-index: 0; }
	 .memb-payment-col-radio .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
        position: relative;
        min-height: 1px;
        padding-right: 15px!important;
        padding-left: 15px!important;
    }
    .memb-bord{ padding: 0px 20px 20px 0px; }
    .memb-first{ padding: 20px; }
    .memb-second:after{
        content: "";
        width: 0;
        height: 0;
        border-bottom: none;
        border-left: none;
        position: absolute;
        left: 0px;
        top: 0px;
    }
	
	.memb-payment-col-radio li{justify-content: center;}
    .hcart_scrollarea {  
        height: calc(100% - 104px);
    overflow-y: auto;
    /* height: 100%;
    max-height: calc(100% - 180px);
    overflow-y: auto; 
    height: 100%; */
 }
    .datetime_selt_sec{ padding: 0px; }
    .foot-section{ margin-top: -70px; }
    .pop-whole-lhs-inner h3{ font-size: 22px;}
    .frgt-head h3{  font-size: 23px; color: #fff; }
    /*.mt-mb-60{ margin-top: 0px;  margin-bottom: 0px;}*/
    /*.about-section:before{  border-right: none;  border-top: 60px solid hsla(87deg, 50%, 79%, 0.1);}*/
    .white-home{
        padding-bottom: 0px!important;
        margin-bottom: 0px!important;
    }
   
    /*.promo-feat-section:before{
        border-right: none;
        display: none;
       
    }*/
    .foot-section:after{
        border-right: none

    }
    /*.banner-info {
        top: 30%;
    }*/
    /*.footer-top{
        padding: 30px 0 10px 0;
    }*/
    /*.footer-main{
        background-size: cover!important;
        background-position: right!important;
    }*/
    .foot-contact{
        margin-bottom: 10px;
    float: left;
    width: 100%;
    margin: 0 60px 0 60px;
    text-align: left;
    }
    .foot-contact p{
        margin-bottom: 5px;
    }
    
    .our-special-section h2, .our-promotions-section h2, .our-featured-section h2{
        font-size: 32px!important;
        line-height: 1;
        margin-top:0px;
    }

    .our-special-section > p{
        margin: 8px 0;
    }

    /*.banner-info{
        margin: 10px 20px 10px 20px;
    }*/
    /*.fs-45 p{
        font-size: 45px!important;
        margin-top: 20px;
    }*/
    .cart_body {
        padding-top: 30px!important;
    }
    .detail-pg-slider button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow
    {
        top: 52%;
    }
    .inn_produc_title{
        font-size: 65px;
    }
    .detail-pg-slider .product-title h3{
        font-size: 18px;
    }
    .outlet-bg h1{
        font-size: 60px;
    }
    .home-banner-main{
        margin-top: 0px;;
    }
    .col-sm-6 {
        width: 100% !important;
    }
    .myacc-info-top p{
        width: 100%;
        text-align: center;
    }
    .myacc-info-top h3{ display: inherit;    text-align: center; }

.hbslider_sec h2{ font-size: 20px;}	
.container_720 { max-width: 100%;}

/*--category--*/
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {font-size: 17px;}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 14px;padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 14px;}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  font-size: 16px;}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ font-size: 14px;}
.delivery-item h2{ font-size: 24px;}
.delivery-item p{ font-size: 16px;}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{ display: block; opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; font-size: 16px; }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; font-size: 16px;  color: #fff; display: block; position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;margin: auto 0;
text-align: center;}
.sub_tab_mobtrigger i:after {content: "\f067";font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 15px;}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 18px;}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 14px;}
.delivery_total_left h3{
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
}
.delivery_total_left span{line-height: normal;}
.redeem-item-hea .points{font-size: 15px;}
.pop-whole-lhs{padding: 50px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.login-popup .mfp-close, .signup-popup .mfp-close, .signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{right: 5px; top:5px;background: #000;opacity: 1;width: 30px;height: 30px;line-height: 30px;}
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;    padding: 20px 35px 0px 35px;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 0px;}
.self_pop_item_loc p{padding: 0 10px; }
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;    height: auto;}
.accsec_right{width: 100%; padding: 20px 15px;}    
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; font-size: 17px; cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; font-size: 18px; width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;	display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a{padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #000;
    font-family: 'poppins';
    font-weight: bold;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;
    text-align: center; 
}
    .focused label { padding: 0 0px;  font-size: 10px;}

    .mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{ 
        background: #105152;
        border: 0; 
        width: 30px; 
        height: 30px; 
        position: absolute; 
        left: 2px; 
        top: 0; 
        bottom: 0; 
        margin: auto 0; 
        text-align: center; 
        overflow: hidden; 
        font-size: 0;
        color: #fff; 
        text-indent: -9px;
        padding: 0
    }
        .mbacc_mslidersec .slick-next{ right: 2px; left: auto;}
        .mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before{ 
        content: "\f104"; 
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
        text-indent: 9px;
        line-height: 25px; 
        font-size: 24px; 
        }
        .mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before{ content: "\f105";}
        .mbacc_mslidersec .slick-track { border-bottom: 4px solid #e7e6e6; }  

    .mbacc_mslide a:before {
        content: "";
        position: absolute;
        left: 51%;
        right: 51%;
        bottom: -4px;
        background: #105152;
        height: 4px;
        transition-property: left, right;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }    
.mbacc_mslide.active a{color: #000;}   
.mbacc_mslide.active a:before{  left: 0;right: 0;}    
    
.main_tabsec_inner{ padding: 10px 0px;}
.mainacc_menusec .main_tabsec_inner .nav-tabs{ margin-bottom: 0px;}
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}

.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; border: 0;}
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; font-size: 16px; height: auto; padding: 13px 15px; border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{font-size: 30px;}
.redeem_pro li h3{font-size: 21px;}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{font-size: 20px;}
.promo-earned-content h2{font-size: 26px;}
.promo-earned-content p{font-size: 15px;}
.abc_code span{font-size: 18px;}
.bg_top_slider h3{font-size: 17px;}
.banner-inner h5{font-size: 15px;}
.banner-inner span{font-size: 13px;}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 5px 15px 15px 15px;}
.pickup-thankfull h4{ font-size: 25px; margin-bottom: 10px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 2;order: 2;  margin: 0px 5px;}
.delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 1;order: 1;  margin: 0px 5px;}
.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tick h2{ font-size: 40px;}
.tnk-detail h2{ font-size: 16px;}
.tnk-order h3{ font-size: 18px;}

/* Contact us */
.contactus_wrapper .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contactus_wrapper .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contactus_wrapper .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ font-size: 22px;}
.award_galinfo h3{font-size: 17px}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
/*.redeem_popsec .promo-earned-content{ width: 65%; }*/
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%; }
.innersection_wrap {  padding: 30px 0 0 0;}
.count_num_t{display: none}    
.web_order_typecls li a{padding: 13px 10px}    
.web_order_typecls{margin-bottom: 30px}    
.promo-form h3 span{    font-size: 35px; line-height: 30px;}    
.promo-form h3{font-size: 24px}    
.mypromo-main-div .myacc_order_details{-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between}    
.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}     
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px;left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0 }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px }  

.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
.back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px }    
.membership_status span{top: 18px}        
.setting_menu_list, .promo-earned-info:before{ display: none;}    
.accprofile{ padding: 20px 10px 20px;}    
footer { margin: 20px 0 0 0;}    
.promo-earned-col-image {  width: 100%;}    
.promo-earned-info { width: 100%; flex-flow: column wrap; padding: 20px 10px;}

 .promo-earned-col-image img{max-width: 100%;}    
}

@media (max-width: 980px){ 
    .promo-feat-section{ padding-bottom: 10px;}
    .head-input-sec{ width: 75%;line-height: 5;margin: 0 auto; }
    .head_btn_sec{ margin-bottom: 15px; }
    /*.Viewreceipt .tnk-order{ background: #d4d5e9;}*/
    .mob-d-show{ display:flex; }
    .abt-inner a{  float: none!important;}
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right{  width: 130px; }
    .inner-bdy{ padding:0; }
    .reward-list-body .myacc_order_details .current_order{  margin-top: 10px; }
    .product-tag-list ul li{ margin: 0 2px;}
    .detail-pg .owl-stage-outer .owl-stage .owl-item{left: 65px;}
    .white-home{ margin-bottom: 20px;}
    .two-links{ padding: 15px 30px 15px 10px;}
    .cart_right { width: 108px;}
    .cart_remove{ right:5px;}
    .mt-20{ margin-top:0px;}
    .m-mt-15{ margin-top: 15px; }
    .prd_chosen_sub_row > p {  margin-top: 0px; }
    .ft-tp-col1, .ft-tp-col2, .ft-tp-col3{  margin: 25px 0px 5px 0px!important; }
    .home-banner-main{ margin-top: 0px;; }
    .custom_center_alertcls{  font-size: 18px!important; }
    .hcart_dropdown .cart-header-first .cart_left h4{  margin-bottom: 0px; text-align: center;  font-size: 30px; }
    .head_left h4{ font-size: 15px; }
    .head_right h4{ font-size: 15px; margin-right: 0px;  font-weight: bold; }
    .focus-out label{ font-size: 12px; z-index: 1; }
    .outlet-bg{ background-size: cover;  background-position: bottom; }
    .mobile-menu .menu{  margin: 0px 0 30px; }
    .product-menu-listing .product-rhs{ 
        background: #fff;
        width: 100%;
        position: fixed;
        height: 100vh;
        right: 0;
        top: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        z-index: 99;
        max-width: 620px;
        padding: 45px 0 55px 0;
    }
    body.cart-items-open .product-menu-listing .product-rhs{ -webkit-transform: translateX(0); transform: translateX(0); }    
    .product-menu-listing .product-rhs .hcart_dropdown{ overflow-y: auto;  height: 100%;  }    
    .product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout { position: fixed; bottom: 0; left: 0; right: 0;}    
    .product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout a{border-radius: 0}     
    .product-rhs .product_orders_top{display: flex}    
    .product-lhs{width: 100%;padding-top: 10px}
}


@media (max-width: 850px){    		
    .home-banner-main{  margin-top: 0px;; }
    .hme-product-inner .title_sec{margin-bottom: 5px;}
    .delivery_bg{ background-size: cover; }
    .popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 10px 15px; height: auto;}
    .popup_right{width: 100%; padding: 10px 15px;}
    .news_postal_popup .popup_right{width: 100%; height: 300px;}
    .awesome_del h2{margin-bottom: 0; font-size: 13px;}
    .title_sec h2{font-size: 29px;}
    .title_sec small{font-size: 14px;}
    .hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
    .hme-prt-slidecontent-inner p{font-size: 13px;}

    .tnk-header{max-width: 100%;}
    .tnk-you .container{ padding-left: 0; padding-right: 0;}
    .tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
    .order-cat-table table{max-width: none;}
    .ord-amount-item{max-width: 97%;}
    .htopinfo p{font-size: 10px;}
    .htopinfo p span{ font-size: 8px;}
    .htopinfo{width: 100%; text-align: center;}
    .htmenu_sec{width: 100%;text-align: center;}
    .horder_btn .btn{width: 100%;}
    /*.inn-product-popup{margin-top: 20px;}*/
    .detail-pg-inner{padding: 50px 10px 0;}	
    /*upadate package*/
    .number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
    .number-break .back_bx:after {top: 10px; font-size: 26px;}
    .number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
    .number-break .break-time {	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
    .number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
    .number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
    .number-break .event-time {width: 100%; padding: 5px 10px;}
    .tbars header{ height: 147px;}
}
@media (max-width: 780px){
    h1{ font-size: 35px}
    h2{ font-size: 30px}    
    h3{ font-size: 26px;}        
    h4{ font-size: 21px;}    
    .home-outlets-list {padding: 40px 0 40px 0;}        
    .date_list {padding: 20px 15px;}
    .time_slot {padding: 20px 15px 20px;}
    .add_time {margin: 10px 15px 20px 15px;}
    .choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
    .cedele-location {padding: 2px 15px 0px 0px;}
    .location-list ul li {font-size: 14px; line-height: 1.3; height: auto; padding: 10px 15px;}
    .catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
    .catering .ttsplitter {margin: 0 auto 10px;}
    .catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
    .checkout-total .checkout-control-group-middle {padding: 20px 0px 0px 0px;}
    .catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
    .event-time .title4 {font-size: 16px;}
    .inner_banner {  height: 250px;}    
    .myacc_order_details .current_order{width: 100%}    
    .web_order_typecls{ margin: 0 0 20px 0 }    
    .order_delivery_item{ padding: 10px 20px 0 20px;}    
    .order_delivery_item { display: flex; -ms-flex-wrap: wrap;  flex-wrap: wrap;  list-style: none;  justify-content: space-between; margin: 0 auto;
        padding: 15px 0 0 0;  max-width: 740px;
    }
    .order_delivery_item li { width: 48%; margin: 0 auto; padding: 0px 0px 20px 0px;}
    .order_delivery_item li .header-ordernow-single-img img { height: 45px; left: 15px;}
    .order_delivery_item h3 { font-size: 21px; }
    /*.checkout-total{margin: 0 auto}   */ 
    .hmenu-login-section {position: static;margin-top:0px;top: inherit; transform: none;}
    .birthday-info-div{margin-bottom: 20px;     padding: 0px 5px 0px 5px;}    
    .hmenu_sec{display: none;}
    .menu_icon.trigger_menunav_act{ display: block; width: 23px; height: 23px; margin: 5px 0px 0px; padding: 0px 0px; cursor: pointer;}    
    .menu_icon .icon-bar{ display: block; background-color: #000; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
    .menu_icon .icon-bar+.icon-bar { margin-top: 6px; }    
     .logo img { width: 100%;}   
 
    .mobile-login ul { left: 0; right: inherit;}   
    .header-top-cls {padding: 0px 0;}    
    .logo { max-width: 190px; position: absolute; left: 0; right: 0; margin: auto;}    
    .logo-main-section { height: 175px;}    
    .profile-info-div { margin: 15px 0;}
    .copyright-section { padding: 10px 10px;}    
    .copyright-section p { font-size: 13px;}    
    .menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link{font-size: 15px; display: flex; justify-content: center; align-items: center;}
    .common-inner-banner{    background-size: contain;}    
    .accsec_right h1 { font-size: 30px;}
    .accsec_right p { font-size: 16px;}    
    .home-banner .btn-act-clss { padding: 10px 16px 10px; font-size: 14px;}    
    .package_details{flex-wrap: wrap; }
    .package_details_left { width: 100%;margin-bottom: 10px}
    .package_details_right { width: 100%;}
      
    .pdr_right.price-fixed { top: 133px;} 
    .featuredpro-section{padding: 40px 0 30px 0}    
    .featured-products-section .products-list-ulmain{justify-content: space-between}    
    .featured-products-section .products-list-ulmain li {width:49%; margin: 0 0 20px 0;}    
    .featured-products-section .products-list-ulmain li:nth-child(4n) { margin-right: 0}    
    .featuredpro-section:before, .welcome-lhs:before{display: none}    
    .featured-products-section h2{padding-bottom: 0}    

    .wh-lhs { margin: 0 auto;}    
    .wh-lhs-top h2 { font-size: 38px;}    
    .wh-lhs-top p { font-size: 16px;}    
    .wh-lhs-bottom ul {padding: 70px 0 0 0;}    
    .welcome-lhs img { visibility: visible;}    
    .testimonial-section h2 { margin: 0 0 10px; font-size: 70px;}    
    .our-promotions-section h2{font-size: 70px; line-height: 1;}  
    .our-featured-section h2{font-size: 70px; line-height: 1;}    
    /*.our-promotions-section.our-special-section{padding-top: 10px}*/
    .our-promotions-section.promo-section .slick-track .slick-slide img { width: 100% }
    
    .our-featured-section.promo-section .slick-track .slick-slide img { width: 100% }
    .promo-three-clm1 { width: 50%; order: 1;}
    .promo-three-clm2 { width: 100%; padding: 20px;    order: 3;}
    .promo-three-clm3 { width: 50%;order: 2;}
    .promo-form .applyBtn{ right: -10px; min-width: 120px;}
    .promo-form .applyBtn{  border-radius: 10px;}
    .footer-main { padding: 30px 0 0 0; /*background-size: contain;*/}    
/*    .footer-top img { width: 180px;}*/
    .footer-top img { margin-top: 10px;}
    .ft-ct-bottom{justify-content: space-between}    
    /*.ft-bt-col1{  width: 100%; padding: 0 0px 30px 0; float: left; text-align: left; }*/
    .social-list{ justify-content: flex-start; }
    .ft-bt-col1 h4{ text-align: center; }
    .ft-list li img { margin-right: 10px;}
    .white-home { padding: 0px 0 0px 0;}
    .home-banner-main{ margin-top: 0px;; }
    .testimonial-section .slick-track .slick-slide > div{padding: 0}    
    .testi-author {  padding: 30px 0 0;}
}

@media (max-width: 767px){
    .controls.single-link{ padding-bottom: 20px;  }
    .self_pop_col .self_pop_col_right {  width: 100%; margin-left: 0; max-width: 100%;}
    .outlet-list-rhs ul li {  margin: 15px 19px 4px 0px; font-size: 11px;}
    .self_pop_col { width: 100%; margin-bottom: 0;}
    .self_outlet { margin-top: 15px; height: auto;}
    .self_outlet_inner {  height: 75px !important;}
    .outlet-list-lhs>img {  max-height: inherit;}
    .outlet-list-rhs ul li:last-child {  margin: 5px 0 0;}
    .self_popup  { padding: 20px 15px;}
    .self_pop_row { height: auto;}
    .detail-pg {max-width: 800px;}

    .title1{font-size: 25px;}
    .title3{ font-size: 18px;}
    .htico_search{ position: static;}
    .hsearch_sec{ left: 15px; right: 15px; width: auto;top: 50px;}
    .banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }

    .location-mapsec > div{ height: 350px;}
    .main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
    .main_tabsec .nav-tabs>li>a{ font-size: 14px; padding: 10px 10px;}
    .myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
    .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
    .video_popup .youtube_player_iframe{ height: 300px;}
    .selfpop_mapouter{ height: 280px !important;}
    .chekout_cart_bar .cart_info{width: auto;}
    .chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
    .redeem-col{padding: 15px 10px;}
    .redeem-item-hea h4{font-size: 20px;}
    .pop-whole-lhs{padding: 40px 15px;}
    .inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 0px 0px;}
    .signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
    .popup-header{padding: 10px 0 10px 0;    background: #105152;}
    .guest_chk_out{margin-left: 0;margin-right: 0;}
    .guest_chk_out .btn{white-space: inherit;height: auto;}
    .pop_title h2{font-size: 25px;}

    /*.order-body { padding: 30px 0;}*/
    .order-body h2{font-size: 21px;}
    .order-body p { color: #000; font-size: 15px; margin: 0px 0 15px 0;}
    section.menu-nav-section {  padding: 0px 0 0 0; position: -webkit-sticky; position: sticky; top: 100px; z-index: 9; background: #000;}
    /*.white-popup { max-width: 365px; padding: 0; background: #d4d5e9;z-index: 11;}*/
    .white-popup.self_popup {  max-width: 362px;}
    .self_outlet_inner { padding-left: 0; list-style: none; text-align: left; overflow-y: auto; margin: 0 0 0px;}
    .self_pop_row { display: flex; flex-wrap: wrap; padding: 15px 15px;}

    .pop_title small{font-size: 17px;}
    .self_pop_row{ height: auto;}
    .self_outlet{margin-top: 15px; height: auto;}   
    .self_pop_col{width: 100%;  margin-bottom: 0;}
    .self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
    .self_popup_map_col{ height: auto; margin: 0;}
    .self_pop_item_map{width: 100%; margin: 0 auto; display: none;}
    .self_popup .self-back{ display: none;}
    .self_outlet_inner{height: 330px;}
    .self_pop_item_loc .or-seperator-row{display: none;}
    .pouup_in{padding: 30px 20px 20px;}
    .redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
    .redeem_popsec .title3{ font-size: 20px;}
    .hme-product-inner .ttsplitter{margin-bottom: 0;}
    .product_chosen_col{width: 100%;margin: 10px 0 0px;}
    .product_chosen_col_inner{margin-bottom: 0px;margin-left: 0;margin-right: 0;}
    .product_chosen_item_left.product_chosen_item_left_full{ margin-bottom: 0px;}
    .accsec_right h1{font-size: 27px;}
    .accsec_right p{font-size: 14px;}
    .accsec_right .ttsplitter_md{margin-top: 20px;}
    .redeem_pro li.pro-subscribe button{font-size: 16px;}
    .outlet_inner{padding: 23px 20px 20px 20px;}
    .breadcrumb_left{width: 100%;display: inline-block;}
    .breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
    .breadcrumb_right .chosen_left{ left: 0; right: auto;}
    .breadcrumb_right .re_select{max-width: calc(100% - 73px);}
    .bakery_inner h3{font-size: 23px;}
    label{font-size: 14px;}
    .process_right p{font-size: 15px;}
    .product_tag{float: none;}
    .product_chosen_col .text-box{margin-left: 0;}

    .top-bar span:after {display: none;}
    /*----Venue Details page----*/  
    .myacc_hea h3 {font-size: 20px;}
    .ttsplitter span {font-size: 14px;}
    .choose_location h4 {font-size: 14px;}
    .choose_location .custom_radio span {font-size: 14px; line-height: 30px;}
    .choose_location .custom_radio span:before {width: 26px; height: 26px;}
    .cedele-location .form-group {margin: 20px 0 20px;}
    .date_list h3 {font-size: 14px;}
    .res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {font-size: 17px;}  
    .res-datetime-picker ul li dl.time_inner dd {font-size: 20px;}
    .res-datetime-picker ul li dl dt {font-size: 12px;line-height: 1;}
    .res-datetime-picker ul li dl.cl-next-days span {font-size: 14px;}
    .res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
    .time_slot .time {padding: 0px 0px 10px 0px;}
    .add_time {margin: 10px 15px 10px 15px;}
    .time_slot .order-btn {padding: 20px 0px 15px;}
    .time_slot h4 {font-size: 14px;}
    .time_slot .order-btn .no {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
    .time_slot .order-btn .yes {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
    .time_slot .add_time .label {float: none; font-size: 14px; display: block; width: auto; text-align: center}
    .time_slot .time label {font-size: 14px;}
    .location-list ul li {font-size: 13px;}
    /*----Checkout page----*/
    .order_details .panel-title>a {font-size: 14px;}
    .order_details .panel-body {padding:15px 10px;}
    .package .package_cnt {padding: 0px 0px 15px;}
    .package .package-list {padding: 0px 0px 10px 0px; color: #000000;}
    .checkout_time {padding: 0px 0px 10px;}
    .checkout_time label {font-size: 14px;} 
    .package .title {font-size: 14px;}
    .package .package-list .one {font-size: 14px;}
    .package .package-list .two {font-size: 14px;}
    .package .qty_bx {margin-top: 10px;}    
       
    .package .amt {margin-top: 15px;}
    .amt span {font-size: 14px;}
    .addon {padding: 15px 0px 15px 0px;}
    .addon h4 {font-size: 14px;}
    .addon_list h5 {margin: 10px 0px 15px 0px;}
    .addon_list h5 .title {font-size: 14px;}
    .addon_list p {margin: 0px 0px 0px 0px; font-size: 14px;}
    .total_amt {padding: 15px 15px 10px 15px;}
    .total_amt .total {font-size: 20px;}
    .total_amt .subtotal {font-size: 14px;}
    .buffet_setup {padding: 10px 0px 15px;}
    .buffet_setup h5 .title {font-size: 14px;}
    .buffet_setup p {font-size: 14px; margin: 0px 0px 7px 0px;}
    .download-quote .btn {font-size: 14px;height: 30px;}
    .catering_checout .redeem-item-hea h4 {font-size: 18px;}
    .catering_checout .redeem-item-hea span {font-size: 14px;}
    .catering_checout .redeem-item-hea .points {font-size: 13px;}
    .catering_checout .redeem_apply .form-control {height: 40px;}
    .catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
    .catering_checout .focus-out .btn {font-size: 14px; height: 40px;}
    /*-----Thank You page------*/
    .catering_thak_you .package .title {padding-left: 0px;}
    .catering_thak_you .order_details .qtybxs {font-size: 14px;line-height: 1.6;}
    .catering_thak_you .package .amt {margin-top: 0px;}
    .catering_thak_you .addon_list h5 .title {padding-left: 0px;}
    .catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
    /*-----Categories------*/
    .number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
    .number-break .back_bx:after {top: 10px; font-size: 26px;}
    .number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
    .number-break .break-time { width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
    .number-break .break-time .title4 {font-size: 15px; margin: 0px 0px 10px;}
    .number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
    .number-break .event-time {width: 100%; padding: 5px 10px;}
    .event-time .form-control { height: 45px;}
    .making-place {font-size: 14px;}
    .event-time .title4 {font-size: 15px;margin-bottom: 7px;}
    .title4 {font-size: 14px;}
    .bts.text-right {text-align: left;}
    .pkselected_filtersec label {font-size: 14px;}
    .pkg_gimg img{width:100%;}
    .pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
    .pkgselect_dd .chosen-container {font-size: 14px;}
    .pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
    .pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
    .pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}  
    /*Addon*/
    .pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
    .pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; font-size: 16px;}
    /*SETUP*/
    .package_controltt .custom_radio span {font-size: 14px;}
    .pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
    .pkgsetup_list .package_formchecklist .custom_checkbox span {font-size: 14px;}
    .package_form_helptext {font-size: 14px;}
    .package_formchecklist .custom_checkbox span b {font-size: 14px;line-height: 1.5;}
    .pkselected_package{margin-bottom: 10px;}   
    .pkglist_pricebx > b {font-size: 16px;}
    .pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
    /*promo*/
    .promation_btn .btn {font-size: 14px; height: 35px; padding: 7px 10px; min-width: 100px; }
    .prodet_bansec{ display: block;}
    .back_arrow{ width: 43px; height: 43px; line-height: 43px; }
    .back_arrow img{ max-width: 11px;}
    .prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
    .prodet_baninfo{width: auto;}
    .product_modifiers_listinner .modifiers_main_list{ width: 100%;}
    .package_checkbx .package_formchecklist .custom_checkbox span{ font-size: 16px}    
    .pkglist_img img{ width: 100% }
    .package_checkrow {margin-bottom: 0px;}    
    .pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; flex-flow: wrap column; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
    .pkselected_filtersec .form-control{ padding-right: 46px }    
    .datelist_bracktime h3, h3.choose-eventtype { font-size: 20px;margin: 0 0 10px 0;}    
    .pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
    .checkout-total .checkout-control-group-bottom textarea{height: 110px}    
    footer{ margin: 10px 0 0 0 }    
    #CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{font-size: 16px}    
    #CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {font-size: 26px; margin: 5px 0 10px 0;}    
    .locate-lirow{ display: block }    
    .mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 9px; right: 10px; }    
    .guest-checkout-popupdiv{margin: 0 -20px;padding: 25px 25px;}    
    .login-popup .inside-popup-rhs{padding-bottom: 0}    
    .productlist-main-div .products-list-ulmain > li{width: 48.5%; margin: 0 0 20px 0;}    
    .common-inner-banner p{font-size: 30px; margin-top: 7px;;}    
    .chk-payment-title h3, .chk-addonpro-title h3 {     font-size: 19px;line-height: 25px; padding-top: 8px;}    
    .chk-payment { padding: 20px 0px 20px 0px;margin-bottom: 0px}    
    .mainacc_toptext { padding:0px 0 5px 0;}    
    .products_sidebar_inner ul li a{font-size: 14px;    line-height: 1.2;}    
    .footer-bottom-middle ul, .footer-bottom-right ul { text-align: center;}
    /*.hcart_round{ right: 13px; top: 17px;}*/
}

@media (max-width: 680px){ 
    .delivery_bg{ width: 100%;}
    .innerproduct-col{width: 100%; margin: 0 0 15px;}
    .cart_body{padding: 10px;}
    .cart_left{ text-align: left;}    
    .tnk-delivery .cart_row{padding-top: 5px;}
    .cart-header-first .cart_right{margin: 20px 0 0 0; text-align: left; }    
    .menu_icon{margin-right: 0;}    
    .delivery-inner{ padding: 0;}
    .delivery-item{	width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;border-bottom: 1px solid #f0e8dd;}
    .delivery-item:last-of-type{border-bottom: 0;}
    .hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
    .hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
    .detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{	z-index: 1;background: #f5f5f5; }
    .inn-product-popup-inner{max-width: 100%;}
    .accpoints_bxin{padding: 20px 13px 55px;}
    .accearn_pointtop span{font-size: 35px;}
    .accsec_right .ttsplitter span{z-index: 1;}
    .ord-amount .row{width: 100%;}
    .ord-rec-sec li{width: 100%; max-width: 100%;}
    .ord-amount [class*='col-']{padding-right: 0;}
    .ord-amount{padding: 10px 0 0 10px;}
    .order-cat-table{padding: 0 10px;}   
    .order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
    .ord-amount h2{font-size: 22px;}
    .order-cat h2{font-size: 18px;}
    .ord-amount-item{max-width: 100%;}
    .cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
    .cur-order-del{ max-width: none; padding: 0 10px; }
    .cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
    .click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
    .cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; font-size: 19px;}
    .cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
    .cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
    .cur-order-del li a.btn{ display: block;}
    .cur-ord-rec-desktop, .click_view_desktop{ display: none;}
    .cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
    .myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
    .myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
    .check-pg-slider .slick-slider{padding: 0;}
    .check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
    .banner-text-box-left{width: 100%; margin-bottom: 10px;}
    .banner-inner h5{ height: auto;}
    .banner-inner span{ height: auto;}
    .banner-text-box-right{width: 100%;}
    .banner-text-box-right .btn_minwid{margin-bottom: 0;}
    .banner-text-box{padding: 20px 18px 20px 20px;}
    .banner-right-content .btn{min-width: 100%;}    
    .checkout-total .checkout-control-group-bottom textarea{height: 100px;}
    .web_order_typecls li a{  padding: 15px 5px;}    
    .promo-earned-info{     -webkit-box-orient: vertical;     -webkit-box-direction: normal;     -ms-flex-flow: column;     flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center}    .promation_btn .button {margin: 5px 5px 0 0;padding: 10px 22px 8px;font-size: 14px;}    
    .product-details .row .cart_left{width: 100%; padding: 0}    
    .product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center }    
    .product-details .row .cart_right .cart_price{ margin: 0 0 0 10px }    
    .function-rooms ul li{ width: 49% }    
    .function-rooms ul li:nth-child(3n){ margin-right:2% }    
    .function-rooms ul li:nth-child(2n){ margin-right: 0 }    
    a.view-rooms img{ min-height: 190px }    
    .birthday-info-top h3 img{ width: 26px}
    .birthday-info-top h3{font-size: 24px}    
    .checkout-body-section { padding: 10px;}    
    .checkout-right-body-section { padding:10px 10px 10px 10px;}    
    .top-bar p {text-align: right;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row {flex-direction: column;justify-content: center;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_row > p {flex: 1 1 auto;}
    .prd_chosen_sub_col {flex: 1 1 auto;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right {flex: 1 1 auto;}
}

@media (max-width: 580px){ 
/*    .order-body {
    padding: 30px 0;
}*/
    .Products .breadcrumb{ padding: 64px 0 0 0;}
    .Products .bread_space { margin: 64px 0 0; }
    .htico_cart{ padding-right: 3px;}	
    .mobile_account_item > a{ font-size: 15px;padding: 14px 40px 10px 40px;}
    .mobile_account_item > a > i{ width: 20px; height: 20px; }	
    .mobile_mainacc_menutrigger, .mobile_account_menu_click i.arrow_myaccount{ background-size: 16px auto; width: 45px; height: 49px;}
    .mobile_account_menu_click{ font-size: 17px; padding: 12px 45px 12px 15px;}
    .mobile_account_delivery_item a, .mobile_account_item_login > a, .mobile_mainacc_menulist li a{  font-size: 17px;}
    .mobile_account_item a.myacunt_act{font-size: 17px;padding: 12px 45px 12px 15px;}
    .loc-cont-part{ display: none;}
    .bakery_row_fixed{ top: 150px;}  
    .tbars .bakery_row_fixed{ top: 184px;}
    .footer-inner [class*='col-']{width: 100%;margin-bottom: 10px;}
    .footer-inner h4{margin-bottom: 10px;}
    .chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;}
    .chk-payment-col-radio li:last-child{margin-bottom: 0;}
    .self-back{top: 5px;font-size: 16px;}
    .pop-whole-lhs{padding: 40px 15px 20px;}
    .pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{font-size: 30px;}
    .pop-whole-lhs p{font-size: 16px;}
    .order_delivery_item li{max-width: 100%;     margin: 0 auto; padding: 0px 0px 20px 0px;}
    .order_delivery_item li:last-child{margin-bottom: 0;}
    /*.order-body{padding: 5px 0;}*/
    .video_popup .order-body{padding: 50px 30px 25px 30px;}
    .self_outlet h2{font-size: 21px;}
    .self_outlet_inner li a{font-size: 14px;}
    .self_pop_item_map{width: 100%;}
    .self_pop_item_map img{width: 100%;}
    .self_pop_item_loc .loc_btn{height: 52px;font-size: 14px;min-width: 100%;}
    .self_pop_item_loc p{font-size: 15px;}
    .hcategory_trigger{ font-size: 14px;padding-right: 30px;    z-index: 21;}    
    .hcategory_trigger:after { width: 16px; height: 15px;}
    .hcategory_trigger.active:after { width: 12px; height: 12px; right: 3px;z-index: 9;} 
    .chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{font-size: 14px;}
    .inn_product_hea p{margin-bottom: 10px;font-size: 13px;}
    .prd_chosen_row{padding: 0px 10px 15px 10px;}
    .prd_chosen_sub_item_left{width: 100%;margin: 5px 0;}
    .prd_chosen_sub_item_right{width: 100%;}
    .prd_chosen_sub_item_right button{max-width: 100%;}
    .footer-inner .news-letter button{padding: 15px 12px;font-size: 14px;}
    .footer-inner h4{font-size: 17px;}
    .footer-inner ul li a{font-size: 14px;}
    .earned_points li h2{font-size: 20px;}
    .tag span{font-size: 12px;}
    .htmenu_sec > li{padding: 0 15px; }
    .megaimg_item{width: 100%;  max-width: 364px;}
    .pkselect_tabsec > .nav-tabs>li>a {font-size: 15px; padding: 10px 10px;}
    .bts .form-group {width: 100% !important;}
    .bts label {margin: 5px 0px 10px; display: block;}
    .pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center}
    .pkselected_package .form-group { width: 100%;} 
    .pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
    .pkselected_break .form-group {width: 100%;} 
    .chk-payment-btn .btn {padding: 10px 22px; height: 37px;font-size: 14px;}
    .chk-payment-btn .btn_download {font-size: 14px; padding: 10px 20px 10px;height: 35px;} 
    .inner_banner {    height: 200px;}
    .mbacc_mslide a{ padding: 15px 10px;    font-size: 16px}
    .web_order_typecls li a h5 { font-size: 14px;}
    .chk_hea > span{display: block; float: none; clear: both; margin: 5px 0 0 0}  
    .upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{font-size: 17px}
    span.myaccount_separate{height: 14px}
    /*21-5-19*/
    .reservation-summary ul li span{font-size: 13px;}
    .reservation-summary{padding: 15px;}
    .reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
    .reservation-full{padding: 20px 0px;}   
    .reservation-full h2{margin-bottom: 15px; font-size: 30px;}
    .reservation-summary-full h3{font-size: 28px; margin-bottom: 15px;}
    .go-back a, .continue a{font-size: 17px;}
    .reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
    .reservation-counter-full{display: block;}
    .reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
    .reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
    .goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
    .goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
    .popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
    .two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
    .delevery_popup .go_second{margin-top: 5px}  
    .products_sidebar_inner .no-slide-res{display: none}    
    .products_sidebar_inner .slick-slider{ display: block;padding: 0 30px; }
    
    .reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
    .reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
    .reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse;}
    .common-top-div.productpage-main-div {  padding-top: 155px!important;}    
    .left-input-div, .right-input-div {max-width: 48.5%;}    
    .subtle{display: none}    
    .redeem-item-hea .points { font-size: 15px; position: relative; width: 100%; max-width: inherit; top: inherit; margin: 7px 0;}    
    .redeem-item-hea-inner { width: 100%;}
    .checkout-heading { padding: 12px 10px 12px;font-size: 18px;}
    .cart_row.tnkorder-first > div h5 { font-size: 14px;} 
    .cart_row.tnkorder-first > div p { font-size: 13px;}    
    .congrats-inner { padding: 30px 15px 20px;}    
    .mobile-order-nowbtn a { padding: 13px 15px; font-size: 15px}      
    .choose-option-hover{display: none}    
    .pdr_right.price-fixed { top: 118px; left: 0;}    
    .footer-bottom { padding-top: 10px; padding-bottom: 15px;}    
    .footer-top a img{ width: 180px } 
    .birthday-info-move { padding: 0px 5px 25px;}    
    .birthday-info-middle{padding: 0}    
    .chk-payment-btn-row{max-width: 95%; width: 100%;margin: 20px auto 0 auto;}  
    .chk-payment-btn-row .button{font-size: 18px}    
    .four-columns-btn {  padding: 15px 15px;font-size: 15px;}    
    .four-columns img { max-width: 70px;}    
    
    .product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 60%;}
}

@media (max-width: 550px){    
    .logo-main-section{ height: 105px; }
    .cms-page .container{  padding: 30px 30px 27px; }    
    .cms-page{ padding: 20px;}
    .bg_top_slider h3{ font-size: 12px;}
    .bg_top_slide p { font-size: 11px; }
    .promo-earned-col-center{ display: block; }
    .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
    .promo-earned-content{ display: block; width: 100%; }
    .promo-earned-content p {font-size: 13px; }
    .redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
    /*.redeem_popsec .promo-earned-content{ width: 100%; display: block; }*/
    .redeem_popsec .promo-earned-content h4 {font-size: 20px; }
    .redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{font-size: 25px; max-height: inherit; overflow: visible;}
    .promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
    .award_galcol{width: 100%; padding: 0}    
    .award_galcol:last-of-type{ margin-bottom: 0 }    
    .award_galinfo{margin: 0;    top: 70px;}
    /*.banner-info h2,*/ .featured-products-main h2, .serving-passionately-right h3{font-size: 17px;    line-height: 35px;}
    .hmenu_list{ width: 200%; max-width: inherit}
    .mypromo-main-div .myacc_order_details .promo-earned-col { width: 100%;  margin: 0 0 15px 0;}    
    .filter_tabsec .nav-tabs>li>a:after{max-width: 50%}    
    .hook{margin-bottom: 30px} 
    .choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 10px; padding-right: 10px;}
    .featured-products-main > ul > li{width: 100%; margin: 0 0 20px 0}    
    .tnk-chk-order .button { padding: 15px 10px 15px; }     
    .productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{width: 100%; margin: 0 0 20px 0;}    
    .tnk-order { padding: 20px 15px 0px;}    
}

@media (max-width: 480px){
    .thankyou-main-div .product-details .cart_img{
        min-width: 70px;
        max-width: 70px;
    }
    .Viewreceipt .delivery_total::before{
        top: 20px;
    }
    .tnk-delivery .delivery_total {
    margin: 0px 0 20px 0 !important;
    padding-top: 10px;
}
    .common-inner-banner .syed-myacc-span {     top: 20px;
    text-align: center;}
    .wrapper { padding: 5px 20px 10px 10px; }
    .takeaway-btn-part { max-width: 100%; flex-wrap: wrap;}
    .takeaway-btn-part .button:first-child { margin-bottom: 5px;}
    .takeaway-btn-part .button { width: 100%;}
    .takeaway-btn-part .btn:first-child {  margin-bottom: 10px; margin-top: 10px;}
    .takeaway-btn-part .btn { width: 100%;}
    .detail-pg .innerproduct-item {flex-direction: column;padding: 15px ​10px !important;max-width: 320px;margin: 0 auto;}
    .detail-pg .detail-pg-box { display: flex;  justify-content: center;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .qty_bx span {width: 35px;height: 35px;} 
    .product-details .container-full > div .inn-product-popup {padding: 45px 10px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a {font-size: 15px;}
    .menu_icon{ width: 30px;}
    .innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}
    .innerproduct-item-text{margin-bottom: 15px;}
    .spilter{text-align: center; margin: 0 5px; vertical-align: middle;}
    .two-links a{ margin: 0 2px; vertical-align: middle;font-size: 13px;}
    .popup-body .sign_reg a{white-space: inherit; height: auto; font-size: 14px;}
    .news_subscribe p{font-size: 14px;}
    .pop_title h2{font-size: 45px;}
    .news_postal_popup .pop_title p{font-size: 14px; line-height: normal;}
    .title1{font-size: 20px;}
    .inn_product_hea_left h3{font-size: 18px;}
    .text-box textarea{font-size: 14px;}
    .popup_social_icon{width: 100%; padding-left: 0;}
    .popup_social_icon li a{padding: 0 8px;}
    .popup_social span{color: #fff;}
    .order-cat-table h4{font-size: 14px;}
    .order-cat-table table td{font-size: 14px;}
    .main_tabsec_inner> .nav-tabs>li> a{ font-size: 12px;}
    .delivery_time [class*='col-']{width: 100%;}
    .chosen_adn_left{width: 280px;}
    .delivery_total.delivery_total_number{padding-bottom: 0;}
    .redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
    .redeem_apply label{ font-size: 12px; top: 17px;}
    .redeem_apply.focused label{ top: -7px;}
    .redeem_popsec .btn{ height: 46px; }  
    .order_delivery_item li .header-ordernow-single-img img { height: 35px;}    
    .our-promotions-section h2{font-size: 25px!important;    line-height: 2;}
    .our-featured-section h2{font-size: 25px!important;    line-height: 2;}
    .order_delivery_item h3{font-size: 14px;     margin: 15px 0 0;}    
    .footer-bottom-left{ width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center;  margin: 10px 0 10px 0;}    
    .footer-bottom-right{width: 100%;    display: -webkit-box;    display: -ms-flexbox;    display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
    .footer-bottom-right ul{padding: 0}    
    .footer-bottom-right h3{margin: 0 5px 0 0}    
    .web_order_typecls li a h5 { font-size: 13px;}    
    .delivery_total_text::before{display: none}    
    .catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 14px;}    
    .catering_days_ul li dl dt{font-size: 10px}    
    .catering_days_ul li dl.cl-next-days span{font-size: 11px}    
    .event-type-maindiv.two-button-row a {  font-size: 16px; padding: 15px 10px;}
    .ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
    .order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center}    
    .order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0}    
    .more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex}    
    .more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; font-size: 16px}    
    .checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0 }    
    .checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0 }    
    .catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
    .checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px}    
    .chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{-ms-flex-wrap: wrap;flex-wrap: wrap} 
    .chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100%} 
    .chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
    .chk-payment-btn-row.checkout-continue-cls .row-replace .button{ font-size: 18px; padding: 14px 10px;}
    #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px}   
    .catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
    .phone-show-dskp{ display: none;}
    .phone-show-mbl{display: inline-block}    
    .locate-lirow .phone-show-mbl a{ color: #000 }    
    .locate-lirow.active .phone-show-mbl a{ color: #fff }  
    .birthday-info-middle p{ padding-top: 20px;} 
    .checkout-billing-address-bottom { padding: 10px 0 20px 0;}
    .product-menu-listing { padding: 10px 0 10px 0;}  
    .mainacc_toptext p { font-size: 15px;}    
    /*.common-inner-banner span { font-size: 14px; line-height: 1.4; margin-top: 65px; padding: 10px;}    */
    .package_details_right_inner{flex-wrap: wrap}    
    .pdr_left{width: 100%}    
    .pdr_right{width: 100%; margin-top: 15px}
    .pdr_right h4 { margin-bottom: 10px;font-size: 20px;}    
    .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{font-size: 15px} 
    /*.mobile-login {  right: 25px;}    */
    .hero-top-desc h4 {font-size: 17px;}    
    .hero-top-desc p{font-size: 13px}    
    .hero-top-desc { padding: 15px 7px 10px;}    
    .hero-top-img {height: 30px;}    
    .hero-top-img img{width: 60px}    
    .wh-lhs-bottom ul li{width: 49%;} 
    .hero-top-desc .button { padding: 10px 15px; font-size: 13px;}    
    .white-home { padding: 0px 0 20px 0;}    
    .wh-lhs-top h2 { font-size: 30px; margin-bottom: 5px;}  
    .wh-lhs-bottom ul { padding: 50px 0 0 0;}
    .ft-tp-col1, .ft-tp-col2, .ft-tp-col3 { width: 100%; margin: 7px 0;}    
    .ft-tp-col1 b, .ft-tp-col2 b, .ft-tp-col3 b{font-size: 15px}    

    .product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 50%;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .pro_det_review .pro_det_row .pro_det_item {
    flex-direction: column; justify-content: center; text-align: center;}
    .mfp-wrap {top: 0 !important;}
} 

@media (max-width: 420px){
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right { width: 137px!important;}
    .hcart_tt{ padding-left: 20px;}
    .hcart_tt h3{ font-size: 16px;}
    .hclear_cart{ font-size: 12px;}
    .location-list ul li {font-size: 12px;}
    .cedele-location P {font-size: 12px;}
    .catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
    .res-datetime-picker .tag {left: -7px;width: 125%;}
    .number-break .back_bx {left: 10px; width: 42px; height: 42px; font-size: 12px; padding: 26px 6px 6px;}
    .number-break .back_bx:after {top: 6px;font-size: 16px;}
    .number-break .break-time {margin-top: 50px;}
    .pkselect_tabsec > .nav-tabs>li>a {font-size: 13px; padding: 7px 7px;}
    .title4 {font-size: 14px;}
    .pkglist_infott h3 {font-size: 16px;}
    .pkglist_infott p{font-size: 13px;}
    .package_checkbx .package_formchecklist .custom_checkbox span {font-size: 13px;}
    .package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 13px;}
    .pkglist_info p{font-size: 13px;}
    .web_order_typecls li a h5 { font-size: 9px;}    
    .main_tabsec .nav-tabs>li>a { font-size: 13px; padding: 5px 6px;}        
    .function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
    .function-rooms ul li:nth-child(3n){ margin-right:0% }    
    .function-rooms ul li:nth-child(2n){ margin-right: 0 }        
    .function-rooms ul li:last-child{ margin-bottom: 0 }    
    .function-rooms ul li a{height: auto}    
    a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
    .catering_days_ul li dl.cl-next-days span {font-size: 10px;}    
    .catering_days_ul li dl dt { font-size: 9px;}    
    .catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 12px;}    
    #PackageEditMdl .pkselected_filtersec .row-replace{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column} .catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
    /*.hcart_round { top: 13px; }*/
     .product-lhs-inner .products_list { padding: 10px 12px 20px 12px;} 
    .featured-products-section .products-list-ulmain li {width: 100%; margin: 0 0 20px 0;}    
    .top-bar span {position: relative;width: 100%;display: block;text-align: center;}
    .top-bar p {text-align: center;width: 100%;}
    .product-details .container-full > div .inn-product-popup {padding: 45px 10px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row {padding: 0;}
    .product-details .container-full > div .inn-product-popup .prodet_bansec {padding: 0;}
}

@media (max-width: 380px){
    
    .tbars header{ height: 154px;}
    .mobile_mainacc_menutrigger{ background-size: 12px auto; width: 32px;}
    .mobile_account_item > a{padding: 14px 25px 10px 40px;  font-size: 14px;}
    .mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}	
    .mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; font-size: 14px;}
    .mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
    .mobile_account_item_login > a{ padding: 14px 0 10px 35px;}
    .mobile_account_menu_click{     padding: 12px 30px 12px 10px; font-size: 14px}
    .mobile_account_delivery_item a{font-size: 14px; padding: 10px 10px;}    
    .mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}
    .delivery_total_left{/*float: none;*/ margin-bottom: 10px;}
    .ttsplitter span{z-index: 1;}
    .myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 14px;}
    .mobile-logo-div{ max-width: 160px }
    .addon_poptop{padding: 0}    
    .addon_popbot{ margin: 0 }    
    .addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
    .addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
    #CartListMdl .total_amt { padding: 15px 15px;}    
    .number-break { padding: 15px 5px 15px 5px;}    
    .number-break .making-catering { padding: 60px 0 0;}   
    .number-break .event-time{padding: 5px 0}    
    .container { padding-left: 10px; padding-right: 10px;}    
    a.go-bck-icon{top:-4px}    
    h3.choose-eventtype { padding-left: 20px; }  
    /*.hcart_round { top: 13px; }  */
    .logo { max-width: 90px;}    
}
@media (max-width: 360px){ 
   /* .mobile-login{ right: 5px;}*/
   /* .banner-info p{  font-size: 17px;}*/
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right { width: 105px!important;}
    .tnk-delivery .cart_info h4{
        font-size: 16px;
    }
    .reservation-summary ul li span{font-size: 11px;}
    .reservation-summary{padding: 10px;}
    .reservation-full h2{margin-bottom: 10px; font-size: 30px;}
    .reservation-summary-full h3{font-size: 26px; margin-bottom: 12px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_left {max-width: 100%;text-align: center;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn_right {float: inherit !important;text-align: center;max-width: 100%;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .chosen_adn {justify-content: center;}
    .product-details {padding: 15px 0;}
    /*.cart_left { width: 60% !important;}*/
    .header-top-cls .hcart_dropdown .hcart_scrollarea .cart_left{ width: 100%!important;}
   /* .cart_right {  width: 35%;}*/
    .cart_price p {font-size: 17px; margin-right: 6px;}
    /*.social-list li {margin: 10px;}*/
    .redeem_apply, .promo_apply {text-align: center;}
    .promo_apply .focus-out, .redeem_apply .focus-out { padding-right: 0px !important; padding-bottom: 10px; width: 100%; display: block;}
    .redeem_apply .button, .promo_apply .button {  position: relative;}
    .chk-payment {padding: 20px 0 0;margin-bottom: 0px;}
    .product-details .container-full > div .inn-product-popup .inn_product_row .tab-menu-container ul li a {font-size: 14px}
    .product-details .container-full > div .inn-product-popup .inn_product_row .prd_chosen_sub_item_right button {margin: 5px 0px;}
}

.close-icon-but {background: #000;}


/********************** OUR LINE START **************************************/

@media (max-width: 1300px){ .menu-section-innerr{ max-height: 430px;}}
@media (min-width: 1400px){ .menu-section-inner{  max-height: 530px;}}
@media (min-width: 1601px){ .menu-section-inner{  max-height: 665px;}}
@media (min-width: 1850px){ .menu-section-inner{    max-height: 788px;}}
@media (min-width: 992px){ .hcategory_sec {  display: none; } }

@media (max-width: 1350px) and (min-width: 1024px){
    .feat-section .our-special-section .addcart_done_maindiv .order_done,
    .promo-feat-section .our-special-section .addcart_done_maindiv .order_done{   padding: 14px 16px 14px; }
}

@media (max-width: 1200px){
    .abt-inner p { padding: 0; }
    .our-promotions-section.happening-section { padding: 0px 0 30px;  }
    .header-top-cls{  background: url("../images/body-bg.jpg") repeat;  }
}


 @media (max-width: 1199px){
    .hcategory_sec {
        background: #ebebeb;
        position: relative;
        border-bottom: 2px solid #181818;
    }
    .hcategory_selected_text {
        padding: 12px 180px 12px 15px;
        color: #000;
        font-size: 18px;
        text-transform: uppercase;
        display: block;
        position: relative;
        font-family: "poppins";
        font-weight: 700;
    }
    .hcategory_trigger {
        position: absolute;
        right: 15px;
        top: 12px;
        -webkit-transition: all .4s;
        transition: all .4s;
        z-index: 1;
        line-height: 1.2;
        font-size: 18px;
        padding-right: 30px;
    }
    .hcategory_trigger:after {
        position: absolute;
        background: url(../images/menu_ico.png) no-repeat;
        background-size: contain;
        width: 22px;
        height: 20px;
        content: "";
        right: 0;
        top: 2px;
    }
    .hcategory_menu {
        background: #f4f4f4;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        max-height: 400px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .4s;
        transition: all .4s;
        overflow: auto;
    }
    .hcategory_menulist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .hcategory_menulist>li>a {
        background: #f8f8f8;
        color: #000;
        font-size: 18px;
        padding: 12px 45px 12px 15px;
        display: block;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }
    .hcategory_submenu {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .hcategory_submenu>li>a {
        color: #000;
        font-size: 18px;
        padding: 12px 15px 12px 35px;
        display: block;
        font-family: 'Poppins';
        font-weight: 700;
    }

    .product-menu-full {
        top: 124px;
    }

    .hcategory_sec.header {
        margin-top: 10px;
        margin-bottom: -10px;
    }

    .cdd-details-lhs.fl .checkout-control-group-top{
        padding-top: 10px;
    }  
}   

@media (max-width: 1080px){
    .featured-products-section .products-list-ulmain .products-single-li, 
    .productlist-main-div .products-list-ulmain .products-single-li {
        width: 32%;
        margin: 0 2% 20px 0;
    }

    .featured-products-section .products-list-ulmain .products-single-li:nth-child(3n),
    .productlist-main-div .products-list-ulmain .products-single-li:nth-child(3n) {
        margin-right: 0;
    }

    .featured-products-section .products-list-ulmain .products-single-li:nth-child(4n), 
    .productlist-main-div .products-list-ulmain .products-single-li:nth-child(4n) {
        margin-right: 2%;
    }

    .product-detail-bg .container-full > div .inn-product-popup .slick-slider .slick-list .slick-track .slick-slide img{
        height: 100%;
    }

/*     .myaccount-banner.common-inner-banner span {
        margin: 75px 0px 20px;
        padding: 0;
    }*/
}

@media (max-width: 1024px){
    .white-home .home-top-four-section .four-columns .availability-sec h3{  font-size: 30px; }
    .mt-mb-60{  margin-top: 0px; margin-bottom: 0px; }
    .ft-bt-col1{ width: 34%;}
    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 {  width: 16.5%; }
}

@media (max-width: 1000px){
    .hsign_sec{display: none!important}
    .hordertype_sec, .f-address-img{display: none} 

    header.small_sticky + .header-top-cls {  background: url(../images/body-bg.jpg) repeat !important; }
    .producy-sticky-bg{ background: url(../images/body-bg.jpg) repeat !important; }

    .header-top-cls { padding: 0; }
    .hcategory_sec.header{  margin-top: 0;  }
    .logo-main-section { height: 112px; padding-bottom: 7px; } 
}

@media (max-width: 1000px) and (min-width: 781px){

     .desk-d-show {
        order: 1;
    }

    .hmenu_sec {
        order: 2;
    }

    .mobile-login {
        order: 3;
    }

    .hmenu-login-section {
        order: 4;
    }

    .logo-main-section ul.desktop_hmenu_list > li a{
        padding: 20px 10px 14px;
    }

    .htico_search>a,
    .htico_cart>a{
        position: relative;
        display: block;
        border: 1px solid #18232c;
        padding: 8px;
        border-radius: 50%;
    }

    .htico_cart>a img {
        width: 19px;
        height: 21px;
        display: block;
    }

    .htico_cart {
        margin-left: 10px;
    }

    a.controller-nav img {
        width: 32px;
    }

    .header-logo img {
        height: 105px;
        padding: 5px 0px;
        padding-left: 0px;
    }

     .hmenu_sec {
        margin-top: 0px;
    }
    .hmenu-login-section {
        margin-top: 0;
    }
}

@media (min-width: 992px){
    .mob-d-show{
        display:none;
    }
    .menu-section-left {
    display: flex;
}    
    .about-section .common-top-div.mt-mb-60{
        margin-top: 0;
    }
}
@media (max-width: 1000px){
.product-menu-full {
    top: 154px;
}
}

@media (max-width: 991px){
.order-body {
    padding: 10px 30px 15px 30px;
}
    .white-popup.login-popup .inside-popup-rhs{
        padding: 0;
    }
    .copyright-section{
        max-width: 100%;
    }
    .banner-info{
        margin: 0 120px;
        padding: 30px 60px;
        right: 60px;
    }

    .productlist-main-div h2{ text-align: left;  font-size: 21px; margin-bottom: 10px; }
    .productlist-main-div .product-category-list .innerproduct > h3{ text-align: left;}

    .main_tabsec .acc-inform .address-info-div .form-group .row, .main_tabsec .acc-inform .profile-info-div .form-group .row{
        margin-left: -15px;
        margin-right: -15px;
    }
    
    .inn_product_row {
        margin-top: 20px;
    }

    .footer-top img { width: 110px;}

    .featured-products-main h2, .serving-passionately-right h3{
        font-size: 30px;
    }

    .ft-list li a{
        font-size: 13px;
    }
    .ft-ct-bottom h4{
        font-size: 15px;
        text-align: left;
        margin: 0 0 10px;
    }

    /*.common-inner-banner span{
        margin: 75px 20px 20px 20px;
    }*/

    .hmenu_sec{
        margin-top: 0px;
    }
    /*.hmenu-login-section{
        margin-top: 5px;
    }*/

     .common-inner-banner{
        min-height: 90px;
        margin-bottom: 0px;
    }
    .detail-pg{
        margin: 0px auto 0;
    }
}

@media (max-width: 991px) {
    .syed-cont-bottom{
        margin-bottom: -90px!important;
    }
}

@media (max-width: 980px){
    .hcart_scrollarea .cart_table .product-details .cart_remove{
        right: 12px;
    }

    .checkout-body-section .checkout-billing-address .form-group1234.mt-20,
    .checkout-body-section .checkout-billing-address.mt-20,
    .checkout-total .checkout-control-group-bottom textarea{
        margin-top: 20px;
    }
}

@media (max-width: 780px){
    .header-logo img {
        height: 105px;
        padding: 5px 0px;
        padding-left: 0px;
    }

    .htico_cart {
        margin-left: 20px;
    }

    .mobile-login{
        order: 2;
    }

    .header-logo {
        padding: 0px 0px 0px 140px;
    }

    .hmenu-login-section {
        order: 3;
    }

    .mobile-login {
        left: 50px;
    }

    .feat-section{
        padding: 30px 0 60px;
    }

    .productlist-main-div .products-list-ulmain .products-single-li {
        width: 48.5%;
        margin: 0 0 20px;
    }

    .featured-products-section .products-list-ulmain .products-single-li:nth-child(4n), 
    .productlist-main-div .products-list-ulmain .products-single-li:nth-child(4n) {
        margin-right: 0;
    }

    .productlist-main-div .products-list-ulmain {
        justify-content: space-between;
    }

    .innerproduct-item-image img {
        width: auto;
    }

    .banner-info {
        width: 536px;
        margin: auto;
        padding: 30px 15px;
        right: 0;
        left: 0;
    }

    .foot-section .ft-bt-col1{
        width: 100%;
        margin-bottom: 20px;
    }

    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 {
        width: 24.5%;
    }
}

@media (max-width: 768px){
    .innerproduct-item {
        width: 100%;
    }
.order_delivery_item {
    padding: 10px 20px 0;
}
    .hcart_dropdown{
        width: 100%;
    }
    body.cart-items-open {overflow: hidden !important;}
    header .hcart_dropdown .hcart_scrollarea {height: auto;overflow-y: initial;}
}

@media (max-width: 767px){

    .prodetailinner-main-div .inn_product_review .inn_product_hea_left h3{
        font-size: 28px;
        margin: 0 0 10px;
    }

    .prodet_bansec .slick-slide>div {
        width: 100%;
    }

    .products-list-ulmain .productDetailMain .inn-product-popup {
        padding: 15px;
    }

    .prodet_bansec {
        width: 100%;
        display:block
    }
    .prodet_baninfo {
        padding: 0 0 10px;
        width: auto;
    }
    .inn_product_hea h4 {
        font-size: 17px;
    }
    .inn_product_hea_left h3 {
        font-size: 18px;
        margin: 0 0 10px;
    }
    .inn_product_hea>p.total_price {
        font-size: 28px;
    }
    .inn_product_row {
        width: 100%;
        padding-left: 0;
    }

}

@media (max-width: 758px){
	.modal .modal-dialog.modal-lg{
		width: 540px;
	}
}

@media (max-width: 720px){
    .productDetailMain .inn_product_row .compo_top_div{
        padding: 0px;
    }

}

@media (max-width: 680px){
    .mobile-login {
        left: 30px;
    }

    .innerproduct:last-of-type{
        margin-bottom: 14px;
    }
    .innerproduct {
        margin: 10px 0 20px;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_row .btn {  
        padding: 7px 10px;
        height: auto;
        line-height: 1;
        margin-left: 10px;
        width: auto;
        margin-top: 10px;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text {
        flex: 1 1 auto;
        min-width: 100px;
        max-width: calc(100% - 160px - (7500vw - 22500px)/467);
        margin: 0;
        padding: 5px 10px 15px 10px;
        text-align: left;
    }
    .innerproduct-item-text{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        left: 0;
        right: 0;
        height: 100%;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text h4{
        font-family: 'poppins';
        font-weight: 700;
        font-size: calc(16px + 4*(100vw - 300px)/380);
        font-weight: 700;
        color: #000;
        display: inline-block;
        padding-top: 10px;
        padding: 0;
        margin: 15px 0 10px;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text p {
        font-family: Open Sans;
        font-size: calc(15px + 1*(100vw - 300px)/380);
        font-weight: 500;
        max-height: 100%;
        text-align: left;
        padding: 7px 6px 0px;
        line-height: 1.5;
        margin: 0;
        overflow: hidden;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer {
        display: flex;
        flex-flow: column wrap;
        margin-top: 5px;
    }
    .innerproduct-item-text .innerproduct-item-footer {
        position: relative;
        bottom: 0;
    }
    .innerproduct-item-footer {
        left: 0;
        right: 0;
        flex-grow: 1;
        justify-content: flex-end;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer .innerproduct-button {
        margin: 10px 0 0;
        justify-content: flex-start;
    }
    .innerproduct-item-text .innerproduct-button {
        margin: 10px auto 0;
        -webkit-transition: all .4s linear;
        transition: all .4s linear;
        display: flex;
        justify-content: center;
    }
   
    .innerproduct-item-text .innerproduct-button .button{
        margin: 0;
        box-shadow: none;
        border-radius: 5px;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_row{
        margin: 0;
        padding: 0;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_row .addcart_row_innr{
        flex-flow: column wrap;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_row .addcart_row_innr{
        justify-content: flex-start;
        flex-flow: row wrap;
        align-items: center;
    }
    .innerproducts-list-maindiv .addcart_row .qty_bx{
        min-width: 100px;
        margin-bottom: 5px;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li:first-child{
        padding-top: 0;
    }
    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin: 10px 0px;
        background: #fff;
        width: 100%;
        border-bottom: 3px solid #060801;
        overflow: hidden;
        position: relative;
    }

    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer
    .innerproduct-button .addcart_done_maindiv .btn.btn_black{
        margin-top: 0;
        margin-bottom: 0;
        padding: 13px 15px 14px;
    }

    .innerproduct-col .innerproduct-item {
        flex: 1 1 auto;
        min-width: 100px;
        max-width: calc(150px + 85*(100vw - 300px)/1300);
        text-align: left;
        height: 100%;
    }

    .innerproduct-item-image{
        height: 100%;
        align-items: flex-start;
    }
    .innerproduct-item {
        position: relative;
    }
    .innerproduct-col .innerproduct-item img {
        max-width: calc(150px + 85*(100vw - 300px)/467);
        max-height: calc(125px + 100*(100vw - 300px)/467);
        width: 100%;
        height: 100%;
    }

    .about-section .col-lg-6 .abt-inner .welcome-note h4{
        margin-bottom: 0px;
    }

    .promo-feat-section:after{
        bottom: -25px;
    }

    .checkout-total .cart_info h4 {
        font-size: 15px;
        letter-spacing: normal;
        line-height: 1.4;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .top-bar p{
        margin: 0;
        color: #fff;
        font-size: 11px;
        float: right;
        padding: 10px;
    }
    .top-bar span{
        font-size: 11px;
        padding: 13px 10px;
    }
    .top-bar span:after{
        content: '';
        width: 0;
        height: 0;
        border-top: 0px solid #e2e2e2;
        border-right: 0px solid transparent;
        position: absolute;
        left: 0px;
        top: 0;
    }
}

@media (max-width: 596px){
    .promo-feat-section .our-special-section .products-single-li{ padding-bottom: 80px; }
}

@media (max-width: 580px){
    .mobile-login{
        order: 0;
        left: -40px;
    }

    .header-logo {
        padding: 0px 0px 0px 0px;
    }

    .header-top-cls .hcategory_sec.header .hcategory_selected_text{
        line-height: 1.2;
    }

    .feat-section .product-info-div .products-ordernow-action .order_done{
        padding: 14px 16px 14px;
    }

    .promo-feat-section .our-special-section .product-info-div .products-ordernow-action .order_done{
        padding: 14px 16px 14px;
    }

    .banner-info {
        width: 430px;
        padding: 15px 15px;
    }

    .banner-info p {
        line-height: 1;
        margin-bottom: 5px;
        font-size: 18px;
    }

    .banner-info h2{
        font-size: 24px;
    }

    .footer-top {
        padding: 45px 0 10px 0;
    }

    .foot-section .ft-bt-col1 {
        width: 260px;
        max-width: 100%;
    }

    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 {
        width: 30.5%;
    }

    .ft-list li a{font-size: 14px;}
    .ft-bt-col1 .ft-list li {   margin: 0 0 10px;}
    .ft-bt-col1 p{font-size: 14px}

    .cartaction_bottom {
        z-index: 99;
    }

    .hcategory_menulist>li>a {
         font-size: 16px;
    }
    .hcategory_selected_text {
        font-size: 16px;
        padding-right: 45px;
    }
    .hcategory_trigger:after {
        width: 16px;
        height: 15px;
    }
    .hcategory_trigger {
        font-size: 14px;
        padding-right: 20px;
        z-index: 1111;
    }
    .hcategory_submenu>li>a {
        font-size: 15px;
        padding-left: 35px;
    }
    .hcategory_menulist>li>a:hover {
        color: #07a8a2;
    }
    .hcategory_trigger.active:after {
        background: url(../images/close-block.png) no-repeat;
        background-size: contain;
        width: 16px;
        height: 16px;
        top: 1px;
        right: 1px;
    }

    .inn_product_hea p {
        margin-bottom: 10px;
        font-size: 13px;
    }
    .prd_chosen_sub_row {
        padding: 0 0 20px;
    }
    .prd_chosen_sub_item_right button {
        max-width: 100%;
    }
    .prodet_bansec .slick-slide img {
        height: 360px;
        object-fit: contain;
        height:auto
    }

    .delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
    .delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}

    .ft-ct-bottom{
        padding: 30px 0 10px;
    }

   /* .common-inner-banner span{
        margin: 75px 20px 20px 20px;
    }
*/
    .checkout-body-section .checkout-billing-address .chk_hea span{
        margin-top: 0;
    }

     .syed-myacc .my-reward-div {
        padding-top: 0px;
    }
    .syed-myacc .myacc-inner-div{
        padding-top: 0px;
    }
}

@media (max-width: 560px){
	.modal .modal-dialog.modal-lg{
		width: 440px;
		margin: 1.75rem auto;
	}
}

@media (max-width: 500px){
    .hcart_scrollarea .cart_row.product-details .cart_left {width: 100%;}

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_info h4{line-height: 1;}
    .checkout-total .cdd-details-rhs .cart_price { margin: 3px 0 3px 0;}
    .syed-cont-bottom{
        /*margin-bottom: -28px!important;*/
    }
}

@media (max-width: 480px){
    .header-logo {
        padding: 0px 0px 0px 0px;
    }
    .mobile-login{
        order: 0;
        left: -25px;
    }
    .logo-main-section {
        padding-bottom: 0px;
    }

    .white-home .home-top-four-section .four-columns .availability-sec h3{
        font-size: 22px;
    }

    .hcart_dropdown .cart_img {width: 50px; max-width: 50px; margin: 0 5px 5px 0;}
    .hcart_scrollarea .cart_row.product-details .cart_left .cart_info { max-width: calc(100% - 70px); }
    .hcart_scrollarea .cart_row.product-details .cart_left{ flex-wrap: wrap; justify-content: flex-start;}

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_img { width: 50px; max-width: 100%; margin: 0 5px 5px 0;}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left .cart_info { width: calc(100% - 70px);}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .cart_left {
        flex-wrap: wrap; justify-content: flex-start; width: calc(100% - 115px);
    }

    .syed-cont-bottom{
        /*margin-bottom: -28px!important;*/
    }

    .our-special-section.our-featured-section > p{
         margin-bottom: 10px;
    }

    .feat-section .our-featured-section.our-special-section h2{
        font-size: 25px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_row .btn {
        margin: 10px 0;
    }
    .innerproduct-col .innerproduct-item-text {
        padding: 5px 0 0;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .button{
        padding: 15px 15px;
        font-size: 12px;
        letter-spacing: 1px;
        border-radius: 5px;
        width: auto;
        height: inherit;
        min-width: 125px;
    }
    .product-tag-list{
        justify-content: left;
    }    
    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx{
        width: 96px;
        height: 30px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx span {
        width: 28px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx span.qty_minus:before {
        width: 10px;
        height: 2px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx input{
        font-size: 12px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx span.qty_plus:before {
        width: 12px;
        height: 2px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx span.qty_plus:after {
        width: 2px;
        height: 12px;
    }

    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer
     .innerproduct-button .addcart_done_maindiv .btn.btn_black {
        padding: 10px 12px 10px;
        font-size: 12px;
        margin-left: 0;
    }

    .prodetailinner-main-div .inn_product_review .inn_product_hea_left h3 {
        font-size: 18px;
        margin: 0 0 0px;
    }

    .prodetailinner-main-div .inn_product_review .inn_product_hea_left h4{
        margin: 0 0;
        font-size: 15px;
    }

    .prodetailinner-main-div .inn_product_review .inn_product_hea>p.total_price{
        margin: 10px 0 0;
    }

    .promo-feat-section:after{
        bottom: -18px;
    }

    .product-menu-listing.product_det_list{
        margin-bottom: -35px!important;
    }

    .pop-whole-lhs-inner h3{
        line-height: 1.4;
    }

    .delevery_popup .pop_title h2 {
        font-size: 32px;
    }

    .forgot-password-popup .log-popup-body .controls.single-link{
        padding-bottom: 0;
    }

    .login-popup .log-popup-body .innervmid_in .datetime_selt_sec .syd_merge .syd_date, 
    .login-popup .log-popup-body .innervmid_in .datetime_selt_sec .syd_merge .syd_time.delivery_submit_cls {
        width: 48%;
        margin: 0 auto;
    }

    .banner-info {
        width: 320px;
        padding: 20px 10px;
    }

    .banner-info p {
        margin-bottom: 0;
        font-size: 16px;
    }

    .banner-info h2 {
        font-size: 16px;
        line-height: 2;
    }

    .delevery_popup_datetime .btn_sec, .takelist-btn-part {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .header-logo img{
        height: 105px;
        padding: 5px 0px;
        padding-left: 0px;
    }

    .alert_fixed p {
        font-size: 15px;
    }

    .hcart_scrollarea .delivery-cart-div .col-sm-cls {
        width: 100%;
        text-align: center;
    }

    .checkout-body-section .checkout-billing-address .chk_hea>span {
        font-size: 12px;
        padding-right: 20px;
    }

    .checkout-total .checkout-body-section .form-group1234 .syd_merge .syd_date, 
    .checkout-total .checkout-body-section .form-group1234 .syd_merge .syd_time.delivery_submit_cls {
        width: 100%;
    }

    .checkout-main-div .custom_center_alertcls {
        max-width: 280px;
    }

    .checkout-main-div .alert_fixed a {
        top: 12px;
    }

    .ordr-hdr .pst-order-body .myacc_head_sec .head_right .head-group h4,
    .ordr-hdr .pst-order-body .myacc_head_sec .head_left .head-group h4 {
        font-size: 13px;
    }

    .reward-list-body .order_amt_div h3{
        font-size: 18px;
    }

}

@media (max-width: 440px){
	.modal .modal-dialog.modal-lg{
		width: 311px;
		margin: 1.75rem auto;
	}
    .redeem_apply .button,
    .promo_apply .button {min-width: 150px;}
    .promo_apply .focus-out,
    .redeem_apply .focus-out {padding-right: 130px;}
    .chk-payment-col-radio li {
        max-width: 100%;
        justify-content: center;
        flex-direction: column;
    }
    .chk-payment .chk-payment-col-radio li label {
        position: relative;
        margin: 0 0 25px;
    }
   /* .custom_center_alertcls.alert-danger {margin: 0 10px !important;}*/
    .birthday-inpt-act {flex-direction: column;}
    .left-input-div, .right-input-div {max-width: 100%;}
}

@media (max-width: 420px){
.innerproduct-col .innerproduct-item img{
    max-width: calc(105px + 85*(100vw - 300px)/467);
    max-height: calc(105px + 100*(100vw - 300px)/467);
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text{
        flex: 1 1 auto;
    min-width: 100px;
    max-width: calc(100% - 120px - (7500vw - 22500px)/467);
    margin: 0;
    padding: 5px 10px 15px 10px;
    text-align: left;
}
.product-spl-price {
    text-align: left;
    padding-bottom: 7px;
}
.innerproduct-col .innerproduct-item {
    flex: 1 1 auto;
    min-width: 80px;
    max-width: calc(120px + 85*(100vw - 300px)/1300);
    text-align: left;
    height: 100%;
}
.innerproduct-item-image {
    height: 100%;
    align-items: flex-start!important;
}
    .menu_icon .icon-bar+.icon-bar {
    margin-top: 8px;
}

.menu_icon.trigger_menunav_act {
    width: 30px;
    height: 22px;
}

a.controller-nav img {
    width: 35px;
}

.htico_search>a>img {
    width: 35px;
    height: 35px;
}

.htico_cart>a img {
    width: 30px;
    height: 35px;
}
    .header-logo {
        padding: 0;
    }
    .mobile-login{
        order: 0;
        left: -5px;
    }
    .hmenu_like{
 margin-left: 10px!important;   
}

    .white-home .home-top-four-section{
        padding: 20px 0;
    }

    .white-home .home-top-four-section .four-columns {
        max-width: 100%;
        height: 150px;
        width: 260px;
        padding: 0;
    }

    .availability-sec, .availability-sec:hover{
        height: 100%;
        max-width: 100%;
    }

    .white-home .home-top-four-section .four-columns .availability-sec img {
        max-width: 40px;
        margin: 0 auto -5px;
        padding-top: 10px;
    }

    .white-home .home-top-four-section .availability-sec .four-columns-btn {
        padding: 10px 12px;
        font-size: 12px;
        max-width: 130px;
    }

    .feat-section {
        padding: 15px 0 50px;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx{
        margin: 0;
    }

    .two-links {
        padding: 15px 0px 0;
    }

    .common-inner-banner p {
        font-size: 22px;
    }

    .foot-section .ft-bt-col1 {
        width: 100%;
    }

    .ft-bt-col2, .ft-bt-col3, .ft-bt-col4, .ft-bt-col5 {
        width: 49.5%;
    }

    .delivery_total_left span{
        font-size: 13px;
    }
    .inn_produc_title{
        font-size: 35px;
    }
    .featured-products-main h2, .serving-passionately-right h3{
        font-size: 16px;
    }

    .checkout-body-section .checkout-billing-address .chk_hea>span {
        text-align: right;
    }

    .order_btns .button{
        padding: 10px 6px 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .order_amt h3 {
        font-size: 18px;
        font-weight: 500;
    }

    .cur-order-body .myacc_head_sec .head_right .head-group h4 {
        font-size: 13px;
    }

    .cur-order-body .myacc_head_sec .head_left .head-group h4 {
        font-size: 13px;
    }

    .catering_receipt_popup .tnk-delivery .order-items-maindiv .cart_left .cart_info h4{
        font-size: 16px;
        line-height: 1;
        letter-spacing: normal;
    }

    .catering_receipt_popup .tnk-delivery .order-items-maindiv .cart_right .cart_price p {
        font-size: 16px;
    }

    .log-popup-body {
        padding: 15px 20px 30px 20px;
    }
}

@media (max-width: 409px) and (min-width: 361px){ 
    .detail-pg .owl-stage-outer .owl-stage .owl-item {
        left: 119px;
    }
    .tnk-you .tnk-delivery .order-items-maindiv .cart_right {
        width: 105px!important;
    }
}


@media (max-width: 400px){
    
    .alert_success{
        margin-top: 10px!important;
        font-size:12px;
    }
    .delivery_total_left span{
        font-size: 13px;
    }
    .delivery_total_left{
        width: 49%;
    }
    /*.cart_img {
        max-width: 70px;
    }
    .check_out_fourcommon .checkout-right-body-section .cart_body .product-details-parent .cart_img{
        min-width: 70px;
    }*/

    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_right .cart_price { margin: 5px 0 7px 0; }

    .cdd-details-lhs.fl .checkout-billing-address .chk_hea .custom_checkbox {
        top: 25px;
    }

    .ordr-hdr .pst-order-body .myacc_head_sec .head_right::before {
        width: 40%;
        right: -20px;
    }
}

@media (max-width: 385px){
    .hcart_scrollarea .qty_bx { width: 100px; height: 35px; }
    .hcart_scrollarea .qty_bx input { font-size: 14px; }
    .hcart_scrollarea .qty_bx span{ width: 35px; }

    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx { width: 100px; height: 35px; }
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx input { font-size: 14px;}
    .checkout-total .cdd-details-rhs.fl .chekout_cart_bar .checkout-right-body-section .qty_bx span{ width: 35px; }
    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_remove { right: 11px;} 
    .checkout-total .overall-parent .product-details .cart_right {
        width: 100px;
    }

    /*.myacc-info-top h3 img{ margin: 0px 0 0 15px; position: relative; width: 50px; top: -5px;}*/

    .myacc-info-top h3{
        line-height: 35px;
        font-size:30px;
    }

    .myacc-info-top h3 img {
        max-width: 100%;
        width: 40px;
    }
}


@media (max-width: 360px){
.product-tag-list ul li{
    font-size: 10px;
}
    .htico_cart{
            margin-left: 10px;
    }
    .menu_icon .icon-bar+.icon-bar {
    margin-top: 8px;
}

.menu_icon.trigger_menunav_act {
    width: 24px;
    height: 24px;
}

a.controller-nav img {
    width: 27px;
}

.htico_search>a>img {
    width: 27px;
    height: 27px;
}

.htico_cart>a img {
    width: 23px;
    height: 28px;
}
    .hcart_scrollarea .cart_row.product-details .cart_right .cart_price { margin: 3px 0 7px 0;}
    .checkout-total .chekout_cart_bar .checkout-right-body-section .product-details .cart_remove { right: 2px; }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx{ margin-bottom: 10px; }

    .productlist-main-div .products-list-ulmain .innerproduct-col.products-single-li .innerproduct-item-text .innerproduct-item-footer 
    .innerproduct-button .addcart_done_maindiv .addcart_row_innr{
        justify-content: center;
        align-items: center;
        flex-flow: column;
    }

    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .addcart_done_maindiv .qty_bx{ right: 0;}
    .banner-info { width: 300px; }
    .alert_fixed p { font-size: 14px; text-align: center; }
    .innerproduct-col .innerproduct-item-text .innerproduct-item-footer .innerproduct-button .button{ font-size: 11px;
    padding: 13px;}

    .chk-payment .chk-payment-col-radio li .radio_con {
        font-size: 14px;
    }
}

@media (max-width: 359px){
    .process_right h5 {
        font-size:16px;
    }
    
    .abt-inner h4 {
        font-size: 27px;
    }
    .abt-inner h4 {
        margin-bottom: 0px;
    }
   
    /*.common-inner-banner span{
        margin-bottom: -20px;
        margin-top: 75px;
    }*/
  
    .featured-products-main h2, .serving-passionately-right h3 {
        font-size: 13px;
        line-height: 35px;
    }
    .head_right::before{
        right: -20px;
        width: 36%;
    }
    .head_right h4{
        font-size: 11px;
        margin-right: -5px;
    }
    .head_left h4 {
        font-size: 13px;
    }
    
    
}


/****************** 13-09-21 ****************************/


@media (max-width: 1199px){
    .pst-order-body .myacc_head_sec .head_left .head-group h4{
        font-size: 14px;
    }

    .pst-order-body .myacc_head_sec .head_right .head-group h4{
        font-size: 14px;
    }

    .delivery_total.delivery_total_number .delivery_total_left h4.checkoutDate,
    .delivery_total.delivery_total_number .delivery_total_left h4.checkoutTime{
        font-size: 18px;
    }

    .custom_points_cls .myacc_head_sec .head_left .head-group h4,
    .custom_points_cls .myacc_head_sec .head_right .head-group h4 {
        font-size: 13px;
    }

    .myvouchar-main-div .promo-earned-code span{
        font-size: 18px;
    }
 
}


@media (max-width: 1080px){
   .myacc_head_sec .head_left .head-group h4 {
        font-size: 14px;
    }

    .myacc_head_sec .head_right .head-group h4 {
        font-size: 14px;
    }

    .pst-order-body .myacc_head_sec .head_right::before{
        width: 44%;
    }

    .custom_points_cls .myacc_head_sec .head_left .head-group h4,
    .custom_points_cls .myacc_head_sec .head_right .head-group h4 {
        font-size: 12px;
    }

    .product-menu-listing .detail-pg{
        margin-top: 0;
    }

}

@media (max-width: 991px){
    .products-single-li .addcart_row .btn{
        min-width: 75px;
    }
    .product-menu-full{
        display: none;
    }
    .hcategory_trigger {
        font-size: 14px;
        padding-right: 30px;
        z-index: 1111;
        line-height: 1.5;
    }
    .hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input{
        margin-top: 20px;
    }
    .mypromo-main-div .promo-earned-info .promation_btn a.button{
        padding: 12px 12px;
    }
    .home-banner-main .home-banner .slick-dots{
        display: none!important;
    }

}

@media (max-width: 980px){
    .inner-bdy .cur-order-body.mypromo-main-div {
        padding: 20px 10px 10px;
    }

    .myvouchar-main-div {
        padding-top: 20px;
    }
}

@media (max-width: 780px){
    .myacc_order_details {
        padding: 0 10px;
    }
/*    .common-inner-banner .syed-myacc {
    top: 0;
} */ 
/*.common-inner-banner .syed-myacc-span{ top: 0; }*/
    .contact_form h3{
        font-size: 22px;
        margin: 15px 0 20px;
    }
}

@media (max-width: 767px){
    .myvouchar-main-div .myacc_order_details .promo-earned-col {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px;
    }
}


@media (max-width: 580px){
    .common-inner-banner .syed-myacc-head { top: 10%;}
   .ordr-hdr .cur-order-body .current_order .order_details_body .order_no_deatails {
        margin: 0 0 15px 0;
        font-size: 16px;
        line-height: 1.4;
    }

    .ordr-hdr .cur-order-body .current_order .order_details_body .delivery_total_text .delivery_total_left h3 {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .promo-form .myuacc-promo input[type=text] {
        font-size: 12px;
        padding: 5px 20px;
        height: 38px;
        border-radius: 5px;
    }

    .promo-form .applyBtn {
        margin-top: 10px;
        height: 38px;
        font-size: 13px;
        padding: 12px 18px;
        position: inherit;
        border-radius: 5px;
    }

    .myvouchar-main-div .myacc_order_details .promo-earned-col {
        width: 100%;
        height: 100%;
        padding: 0;
    }

    .myvouchar-main-div .myacc_order_details .promo-earned-col .promo-earned-col-item .promo-earned-col-image{
        height: 100%;
    }

    .myvouchar-main-div {
        padding: 20px 10px 10px;
    }

    /*.jxPOhn {
        margin-bottom: 77px;
        margin-top: 0;
    }*/
}
@media (min-width: 980px) and (max-width: 1080px){
.common-inner-blckdiv {
    /*margin-bottom: 20px;*/
}
}

















@media (max-width: 768px){ 
    .hcategory_submenu>li>a{
        padding: 12px 100px 12px 45px;
    }
}    
@media (max-width: 480px){
    .detail-pg .notify-box {
        flex-direction: inherit!important;
    }  
    .syd_date, .syd_time.delivery_submit_cls {
    width: 100%!important;
}
}
@media (max-width: 380px){
.mobile-login{
        order: 0;
        left: -5px;
    }
    }

@media (max-width: 330px){
.hcart_tt h3 {
    font-size: 14px;
}
}

@media (max-width: 310px){
    .product-tag-list ul li{
    font-size: 9px;
}
}

@media (max-width: 480px){
@supports (-webkit-overflow-scrolling: touch) {
    .hcart_scrollarea { max-height: 100%; padding-bottom: 200px;}
    .hcart_dropdown .cartaction_bottom{   padding: 10px 0px 120px 0px;   background: #fff;}
}    
}